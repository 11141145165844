import * as React from "react"
import { useEffect } from 'react';
// import { useStateIfMounted } from "use-state-if-mounted";
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
// import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';

import PanoramaImg from '../assets/images/panorama/01_0106.jpg'
// import PanoramaImg from '../assets/images/panorama/sun_temple_stripe.jpg'
// import PanoramaImg from '../assets/images/panorama/sun_temple_stripe_stereo.jpg'

// import { OrbitControls } from '@lib/three/examples/jsm/controls/OrbitControls.js'
// import { GLTFLoader } from '@lib/three/examples/jsm/loaders/GLTFLoader.js';
// import { DRACOLoader } from '@lib/three/examples/jsm/loaders/DRACOLoader.js';
// import { OBJLoader } from '@lib/three/examples/jsm/loaders/OBJLoader.js';
// import { MTLLoader } from '@lib/three/examples/jsm/loaders/MTLLoader.js';
// import { TWEEN } from '@lib/three/examples/jsm/libs/tween.module.min'
// import Stats from '@lib/stats/Stats.js';


const threeFunc = () => {

  // サイズ------------------
  const wrap = document.querySelector(".js-wrap-a");
  let wrapWidth = wrap.clientWidth;
  let wrapHeight = wrap.clientHeight;

  // レンダラー------------------
  const renderer = new THREE.WebGLRenderer({
    canvas: document.querySelector(".js-canvas")
  });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(wrapWidth, wrapHeight);

  // シーン------------------
  const scene = new THREE.Scene();

  // カメラ------------------
  const camera = new THREE.PerspectiveCamera(75, wrapWidth / wrapHeight, 1, 1000);
  camera.position.set(0, 0, 1);

  // カメラコントローラーを作成
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.autoRotate = false; // 自動回転をONにする
  controls.autoRotateSpeed = 0.5; // 自動回転の速度
  controls.enableDamping = true; // 視点操作のイージングをONにする
  controls.dampingFactor = 0.2; // 視点操作のイージングの値
  controls.rotateSpeed = -0.3; // 視点変更の速さ
  controls.enableZoom = false; // ズーム禁止

  // 光源------------------
  // 環境光源
  const aLight = new THREE.AmbientLight(0xffffff, 1);
  scene.add(aLight);

  // テクスチャー------------------
  const loader = new THREE.TextureLoader();
  const texture = loader.load(PanoramaImg);

  // 物体------------------
  //球体
  const sphere = new THREE.Mesh(
    new THREE.IcosahedronGeometry(10, 15),
    new THREE.MeshPhongMaterial({
      map: texture
    }),
  );
  sphere.geometry.scale(-1, 1, 1); //表面を内側に向ける
  scene.add(sphere);

  //リサイズ------------------
  const wrapResize = () => {
    // サイズ
    wrapWidth = wrap.clientWidth;
    wrapHeight = wrap.clientHeight;
    // レンダラー
    renderer.setSize(wrapWidth, wrapHeight);
  }

  //一定時間毎に処理------------------
  let tick;
  const switching = (e) => {
    if (e[0].isIntersecting) { //見えてる時
      tick = () => {
        wrapResize(); //リサイズ
        controls.update(); //カメラコントローラー
        renderer.render(scene, camera); //レンダリング
        requestAnimationFrame(tick); //繰り返し
      }
      requestAnimationFrame(tick);
    } else { //見えてない時
      tick = () => {
        cancelAnimationFrame(tick)
      }
    }
  }

  //見えているかどうか（Intersection Observer API）------------------

  //要素の監視
  const createObserver = () => {
    let observer;
    const options = { root: null, rootMargin: "0%", threshold: 0 };
    observer = new IntersectionObserver(switching, options); //コールバック関数とオプションを渡す
    observer.observe(wrap); //要素の監視を開始
  }

  //実行
  createObserver();

}


const ThreePage = () => {

  useEffect(() => {

    threeFunc();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pageThree">
      {/* CONTENTS */}

      <>
        <div className="wrap js-wrap-a">
          <canvas className="canvas js-canvas"></canvas>
        </div>
      </>


      {/* CONTENTS */}
    </div>
  )
}

export default ThreePage
